import styled, { css } from 'styled-components';

const StyledUrlHandleComponent = styled.div`
  ${({ theme }) => {
    const { palette } = theme;
    return css`
      #expiryIntervalContainer {
        position: relative;
        .handleInputContainer {
          display: flex;
          align-items: center;

          &.error {
            .disabledLabel {
              border-color: #e32525 !important;
            }
            .inputContainer {
              @media (max-width: 576px){
                
              }
              input {
                border-color: #e32525 !important;
                #dark & {
                  border-color: #e32525 !important;
                }
              }
              
            }
          }

          .inputContainer {
            position: relative;

            input {
              font-size: 14px;
              line-height: 20px;
              width: 210px;
              height: 36px;
              border-color: ${palette.border[0]} !important;

              @media (max-width: 576px){
                width: 100%;
                border-left: 1px solid;
              }
              #dark & {
                border-color: ${palette.border[4]} !important;
              }
              &[disabled] {
                background-color: ${palette.secondary[0]};
                #dark & {
                  background-color: ${palette.background[1]} !important;
                }
              }
            }
            .react-loading-skeleton {
              --base-color: #f3f2f2;
              --highlight-color: #e2e2e2;
              --animation-duration: 0.5s;
              --animation-direction: normal;
              --pseudo-element-display: block; /* Enable animation */

              background-color: var(--base-color);
            }

            .input-skeleton {
              position: absolute;
              display: flex;
              left: 10px;
              top: 50%;
              transform: translateY(-50%);
              flex-wrap: nowrap;
              align-items: center;
              .react-loading-skeleton {
                #dark & {
                  background-color: ${palette.background[1]};
                }
              }
            }
            
            .human-readable {
              margin-left: 8px;
              padding-top: 11px;
              font-size: 14px;
              line-height: 14px;
              margin-bottom: 0;
            }
          }
        }
        .labelError {
          position: absolute;
          margin-top: 4px;
          top: 100%;
          left: 0px;
          font-size: 12px;
          line-height: 16px;
          color: #e32525;
          margin-bottom: 0px;
        }
      }
    `;
  }}
`;

StyledUrlHandleComponent.displayName = 'StyledUrlHandleComponent';
export default StyledUrlHandleComponent;
