import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Tooltip from 'theme/uielements/tooltip';
import Input from 'theme/uielements/input';
import Button from 'theme/uielements/button';
import StyledEnvironmentExpiryInterval from './EnvironmentExpiryInterval.style';

const EnvironmentExpiryInterval = ({
  error,
  inputValue,
  updateInputValue,
  isLoading,
  isSaving,
  aclIsLoading,
  aclManageDenied,
}) => {

  const formatExpiryInterval = (intervalSeconds) => {
    if (intervalSeconds === 0) {
      return 'no auto stop';
    }
    if (!intervalSeconds || Number.isNaN(parseInt(intervalSeconds, 10))) {
      return '';
    }

    const hours = Math.floor(intervalSeconds / 3600);
    const minutes = Math.floor((intervalSeconds - hours * 3600) / 60);
    const seconds = intervalSeconds % 60;

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <StyledEnvironmentExpiryInterval>
      <div id="expiryIntervalContainer">
        <div className={`handleInputContainer ${error ? 'error' : ''}`}>
          <Tooltip placement="top" title={aclManageDenied ? 'Denied' : ''} mouseEnterDelay={0.4} mouseLeaveDelay={0.3}>
            <div className="inputContainer d-flex">
              <Input
                  className="d-flex"
                  disabled={isSaving || isLoading || aclManageDenied || aclIsLoading}
                  value={inputValue}
                  placeholder="Seconds or 0"
                  onChange={({target}) => {
                    updateInputValue(target.value);
                  }}
              />
              <p className="human-readable d-flex">{formatExpiryInterval(inputValue)}</p>
            </div>
          </Tooltip>
        </div>
        {error && <p className="labelError">{error}</p>}
      </div>
    </StyledEnvironmentExpiryInterval>
  );
};

EnvironmentExpiryInterval.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  inputValue: PropTypes.number.isRequired,
  updateInputValue: PropTypes.func.isRequired,
  aclManageDenied: PropTypes.bool.isRequired,
  aclIsLoading: PropTypes.bool.isRequired,
};

export default EnvironmentExpiryInterval;
